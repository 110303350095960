import { Box } from "@mui/material";
import { TimesheetSubStatusLabel } from "commons/Enums";
import React from "react";
import Textarea from "react-textarea-autosize";

type ResetDidNotWorkDialogContentProps = {
  message: string;
  timesheetSubStatus: string;
  noteContent: string;
  setNoteContent: (newValue: string) => void;
};

const ResetDidNotWorkDialogContent = ({
  message,
  timesheetSubStatus,
  noteContent,
  setNoteContent,
}: ResetDidNotWorkDialogContentProps): JSX.Element => {
  return (
    <Box>
      {message}
      {timesheetSubStatus === TimesheetSubStatusLabel.DidNotWork && (
        <Box mt={2}>
          <Textarea
            value={noteContent}
            style={{ width: "100%", resize: "none" }}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="write your note (optional)."
            minRows={4}
            maxRows={8}
            maxLength={200}
          />
          <p className="note-characters-left">{200 - (noteContent ? noteContent.length : 0)}</p>
        </Box>
      )}
    </Box>
  );
};

export default ResetDidNotWorkDialogContent;
